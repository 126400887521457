import React, { useState, useEffect, useContext } from "react";
import * as classes from "../Styles/MoreShare.module.css";
import { PREVIEW } from "../../../../../project-config.js";
import { I18nContext } from "../../../../../i18n/index.js";
import { useLocation } from "@reach/router";
import {
  MdCompareArrows,
  MdFavorite,
  MdFavoriteBorder,
  MdLink,
  MdMoreHoriz
} from "react-icons/md";

import {
  TwitterShareButton,
  FacebookShareButton,
  EmailShareButton,
  LineShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  WhatsappShareButton,
  TwitterIcon,
  FacebookIcon,
  EmailIcon,
  LineIcon,
  LinkedinIcon,
  PinterestIcon,
  WhatsappIcon
} from "react-share";

export default function MoreShare({
  moreActive,
  setMoreActive,
  compareClicked,
  wishlistClicked,
  id,
  url,
  title,
  imageUrl,
  isItemWishlisted
}) {
  const location = useLocation();
  const [wishlistState, setWishlistState] = useState(false);

  useEffect(() => {
    setWishlistState(isItemWishlisted);
  }, [isItemWishlisted]);
  const { translate, langCode } = useContext(I18nContext);

  const [productUrl, setProductUrl] = useState("");
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    url = url.replace("product/", "");
    console.info("url", url);
    let langCodePart = langCode == "en" ? "" : `/${langCode}`;

    setProductUrl(location.origin + "/" + url);
  }, []);

  const handleCopyToClipboard = event => {
    event.stopPropagation();

    console.info(event.target);

    let textField = document.createElement("textarea");
    textField.innerText = productUrl;
    document.body.appendChild(textField);
    textField.select();
    textField.setSelectionRange(0, 99999);
    document.execCommand("copy");
    textField.remove();
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const handleMoreShareClicked = event => {
    console.info("clicked");
    event.stopPropagation();
    setMoreActive(true);
  };

  const renderTheMoreWindow = () => {
    return (
      <div
        style={langCode == "ar" ? { right: "0", left: "unset" } : {}}
        className={`no-select ${classes.moreWindow}${
          moreActive ? ` ` + classes.active : ``
        }`}
      >
        <li
          onClick={event => compareClicked(event, id)}
          className={`${classes.moreWindowListItem}`}
        >
          <i className="material-icons">
            <MdCompareArrows />
          </i>
          <p>{translate(`compare.compare_title`)}</p>
        </li>
        <li
          onClick={event => {
            wishlistClicked(event, id);
            setWishlistState(!wishlistState);
          }}
          className={`${classes.moreWindowListItem}`}
        >
          <i className="material-icons">
            {wishlistState ? <MdFavorite /> : <MdFavoriteBorder />}
          </i>

          <p>{translate(`moreShare.addToWishList`)}</p>
        </li>
        <li
          className={`${classes.moreWindowListItem} ${classes.moreWindowListItemicons}`}
        >
          <EmailShareButton
            separator={" "}
            className="share-button"
            url={productUrl}
            subject={`This ${title} Awesome`}
            body={`Check this ${title}.`}
          >
            <img
              className="filter-outlookemail"
              style={{
                width: "29px"
              }}
              src="https://ik.imagekit.io/ofb/ofb/email_c08n2FXNH3.svg?tr=dpr-2,pr-true,f-auto,w-47"
              alt="email"
            />
          </EmailShareButton>
          <TwitterShareButton
            className="share-button"
            url={productUrl}
            title={`Check this ${title}.`}
            hashtags={["Avetti", "DemoStore", "B2B", "ecommerce"]}
          >
            <img
              className="filter-twitter"
              style={{
                width: "29px"
              }}
              aria-hidden="true"
              width="47"
              height="47"
              src="https://ik.imagekit.io/ofb/ofb/twitter_DkmqzF6MF.svg?tr=dpr-2,pr-true,f-auto,w-47"
              alt="Twitter"
              title="Twitter"
            />
          </TwitterShareButton>
          <FacebookShareButton
            className="share-button"
            url={productUrl}
            quote={`Check this ${title}.`}
            hashtags={["Avetti", "DemoStore", "B2B", "ecommerce"]}
          >
            <img
              className="filter-facebook"
              style={{
                width: "29px"
              }}
              src="https://ik.imagekit.io/ofb/ofb/facebook_ouzk_ScMN.svg?tr=dpr-2,pr-true,f-auto,w-47"
              alt="facebook"
            />
          </FacebookShareButton>
          <LinkedinShareButton
            className="share-button"
            url={productUrl}
            title={`This ${title} Awesome`}
            summary={`Check this ${title}.`}
            source={`Avetti Demo Store`}
          >
            <img
              className="filter-linkedin"
              style={{
                width: "29px"
              }}
              src="https://ik.imagekit.io/ofb/ofb/linkedin_f_OGzK1Z9.svg?tr=dpr-2,pr-true,f-auto,w-47"
              alt="LinkedIn"
            />
          </LinkedinShareButton>
        </li>
        <li
          className={`${classes.moreWindowListItem} ${classes.moreWindowListItemicons}`}
        >
          <PinterestShareButton
            className="share-button"
            url={productUrl}
            description={`Check this ${title}.`}
            media={imageUrl}
          >
            <img
              className="filter-pinterest"
              style={{
                width: "29px"
              }}
              src="https://ik.imagekit.io/ofb/ofb/pinterest__FbW-B6_t.svg?tr=dpr-2,pr-true,f-auto,w-47"
              alt="Pinterest"
            />
          </PinterestShareButton>

          <LineShareButton
            className="share-button"
            url={productUrl}
            title={`This ${title} Awesome`}
            description={`Check this ${title}.`}
          >
            <img
              className="filter-lineshare"
              style={{
                width: "29px"
              }}
              src="https://ik.imagekit.io/ofb/ofb/line_mQt2JwetZ.svg?tr=dpr-2,pr-true,f-auto,w-47"
              alt="Whatsapp"
            />
          </LineShareButton>
          <WhatsappShareButton
            separator={" "}
            className="share-button"
            url={productUrl}
            title={`Check this ${title}.`}
          >
            <img
              className="filter-whatsapp"
              style={{
                width: "29px"
              }}
              src="https://ik.imagekit.io/ofb/ofb/whatsapp_R78MHvHF8.svg?tr=dpr-2,pr-true,f-auto,w-47"
              alt="Whatsapp"
            />
          </WhatsappShareButton>
        </li>
        <div className={classes.seperator}></div>
        <li
          onClick={event => handleCopyToClipboard(event)}
          className={`${classes.moreWindowListItem}`}
        >
          <i className="material-icons">
            <MdLink />
          </i>
          <p>
            {copied
              ? translate(`moreShare.copiedToClipboard`)
              : translate(`moreShare.copyToClipboard`)}
          </p>
        </li>
      </div>
    );
  };

  const renderTheBackDrop = () => {
    return (
      moreActive && (
        <div
          onClick={event => {
            console.info("clicked", moreActive);
            event.stopPropagation();
            setMoreActive(false);
          }}
          className={classes.backdrop}
        ></div>
      )
    );
  };

  return (
    <React.Fragment>
      {renderTheBackDrop()}

      <div
        className={classes.container}
        onClick={event => handleMoreShareClicked(event)}
      >
        <i
          className={`no-select material-icons ${classes.moreBtn}${
            moreActive ? ` ` + classes.moreBtnActive : ``
          }`}
        >
          <MdMoreHoriz style={{ display: "flex", fontSize: "25px" }} />
        </i>
        {renderTheMoreWindow()}
      </div>
    </React.Fragment>
  );
}
